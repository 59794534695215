import ApiHelper from './index';

async function fetchAllRemoteDevices(spinner = true, queryParams = {}) {
  return await ApiHelper.get(`device/remote_cam/`, spinner, queryParams);
}

async function addNewRemoteDevice(spinner = true, payload) {
  return await ApiHelper.post(`device/remote_cam/`, payload, spinner);
}

const updateRemoteCamDevice = async (payload, spinner = true) => {
  return await ApiHelper.patch(
    `device/remote_cam/${payload.id}/`,
    payload,
    spinner
  );
};

const updateRemoteCamDetails = async (payload, spinner = true) => {
  console.log('inside api call: ', payload.id, payload);
  return await ApiHelper.patch(
    `device/remote_cam/${payload.id}/`,
    payload,
    spinner
  );
};

const deleteRemoteCam = async (camId, spinner = true) => {
  return await ApiHelper.remove(`device/remote_cam/${camId}/`, spinner);
};
export default {
  fetchAllRemoteDevices,
  addNewRemoteDevice,
  updateRemoteCamDevice,
  updateRemoteCamDetails,
  deleteRemoteCam,
};
