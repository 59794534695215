import UserService from 'src/services/user';
import types from '../mutation-types';

const state = {
  roles: [],
};

const getters = {
  roles: (state) => state.roles,
};

const actions = {
  async addNewRole({ commit, dispatch }, payload) {
    const [error,data] = await UserService.addNewRole({name:payload})
    if(error) {
      console.log("Couldn't add new role!")
      return 0
    }
    commit(types.ADD_ROLE, data);
    dispatch('getAllRoles');
    return 1
  },

  async getAllRoles({ commit }) {
    const [error, roles] = await UserService.allRoles()
    if(error) return
    if (roles.length >= 1) {
      commit(types.GET_ROLES, roles);
    }
  },
};

const mutations = {
  [types.ADD_ROLE](state, payload) {
    state.roles = [...state.roles, { ...payload }];
  },
  [types.GET_ROLES](state, payload) {
    state.roles = [...payload];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
