import types from '../mutation-types';
import httpClient from '../../service/httpClient';
import { useToast } from 'vue-toastification';
import OrganizationService from 'src/services/organization';

const toast = useToast();
const logoUrl = 'https://retrocausal-video-thumbnails.s3.us-east-2.amazonaws.com/logos/'

const state = {
  organizations: [],
  isLoadingOrganizations: false,
  organizationList: [],
  organizationCount: 0,
  currentOrgPage: 1,
};

const getters = {
  organizations: state => state.organizations,
  isLoadingOrganizations: state => state.isLoadingOrganizations,
  currentOrgPage: state => state.currentOrgPage,
  organizationList: state => state.organizationList,
  organizationCount: state => state.organizationCount
};

const actions = {
  async getOrganizations({ commit }) {
    commit(types.SET_IS_LOADING_ORGANIZATIONS, true);
    const list = await httpClient.get('organization/', false);
    commit(types.SET_IS_LOADING_ORGANIZATIONS, false);
    commit(types.SET_ORGANIZATION_LIST, list);
  },

  async getPaginatedOrganizations({ commit, state }, searchValue) {
    commit(types.SET_IS_LOADING_ORGANIZATIONS, true);
    const { currentOrgPage } = state;

    let query = `page=${currentOrgPage}`;
    if (searchValue)
      query += `&search=${searchValue}`;
    const [error, data] = await OrganizationService.getPaginatedOrganizations(query);

    if (error) {
      console.log({ error });
      return;
    }
    const list = data.results.map(org => ({ ...org, logoImage: logoUrl + org.Org_name + '.jpg' }));
    commit(types.SET_ORG_TOTAL_COUNT, data.count);
    commit(types.SET_ORG_PAGINATED_LIST, list);
    return data;
  },

  async addNewOrg(context, payload) {
    const requestData = JSON.stringify(payload);
    return await OrganizationService.addNewOrganization(requestData)
  },

  handlePageChange({ commit }, payload) {
    commit(types.SET_ORG_PAGE, payload);
  },

  async updateSingleOrg(context, payload) {
    const { id, ...resData } = payload;
    const requestData = JSON.stringify(resData);
    const [error] = await OrganizationService.updateOrganization(id, requestData)
    if (error) {
      console.log('error: ', error)
      return 0
    }
    return 1
  },

  async deleteSingleOrg(context, payload) {
    const [error] = await OrganizationService.deleteOrganization(payload)
    if (error) {
      toast.error('Error occured while deleting the organization!');
      return;
    }
  },

  async refreshVideos({ commit, dispatch }, payload) {
    const url = `cloud_source_labeling/refresh_urls_for_trainig_videos/?Org_name=${payload}`;
    const response = await httpClient.getData(url);
    return response;
  },

  async switchOrganization({ commit }, payload) {
    const [error, data] = await OrganizationService.switchOrganization(payload);
    if (error) {
      toast.error(error?.response.data.error);
      return false;
    }
    toast.success('User switched successfully');
    return true;
  }
};

const mutations = {
  [types.SET_ORGANIZATION_LIST](state, payload) {
    state.organizations = payload;
  },

  [types.SET_ORG_PAGINATED_LIST](state, payload) {
    state.organizationList = payload;
  },

  [types.SET_ORG_TOTAL_COUNT](state, payload) {
    state.organizationCount = payload;
  },

  [types.SET_IS_LOADING_ORGANIZATIONS](state, payload) {
    state.isLoadingOrganizations = payload;
  },

  [types.SET_ORG_PAGE](state, payload) {
    state.currentOrgPage = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
