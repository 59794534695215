const CDN_URL = process.env.VUE_APP_CDN_URL;
export const ASSETS = {
  //   BACKGROUND_IMAGE: `${CDN_URL}/background.png`,
  //   LOGO: `${CDN_URL}path/to/your/logo.png`,
  //   ICON: `${CDN_URL}path/to/your/icon.png`,
  FUNDAMENTALS: {
    VIDEO_URL: {
      1: `${CDN_URL}/Fundamentals/1_What_is_Kaizen.mp4`,
      2: `${CDN_URL}/Fundamentals/2_WHAT_is_WORK.mp4`,
      3: `${CDN_URL}/Fundamentals/3_CAT_OF_WORK.mp4`,
      4: `${CDN_URL}/Fundamentals/4_TIMWOODS.mp4`,
      5: `${CDN_URL}/Fundamentals/5_How_to_Identify_the_Waste.mp4`,
      6: `${CDN_URL}/Fundamentals/6_521h_ecssra.mp4`,
      7: `${CDN_URL}/Fundamentals/7_Therbligs.mp4`,
      8: `${CDN_URL}/Fundamentals/8_Micromotion.mp4`,
      9: `${CDN_URL}/Fundamentals/9_MOST.mp4`,
      10: `${CDN_URL}/Fundamentals/10_PTMS.mp4`,
      11: `${CDN_URL}/Fundamentals/11_MACROMOTION_ASSY_LINE.mp4`,
      12: `${CDN_URL}/Fundamentals/12_BILL_OF_MATERIALS.mp4`,
      13: `${CDN_URL}/Fundamentals/13_STANDARDIZE_WORK.mp4`,
      14: `${CDN_URL}/Fundamentals/14_WHY_TO_FOLLOW_STD_WORK.mp4`,
      15: `${CDN_URL}/Fundamentals/15_SD_WORK_Reach_Zone.mp4`,
      16: `${CDN_URL}/Fundamentals/16_WORK_CELL.mp4`,
      17: `${CDN_URL}/Fundamentals/Practical_Kaizen.mp4`,
    },
  },
};
