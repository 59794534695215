//firebase.js

import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBlxlvbzXWxtZm9zR9miarICa4_7XKRlVM',
  authDomain: 'test-portal-app.firebaseapp.com',
  databaseURL: 'https://test-portal-app-default-rtdb.firebaseio.com',
  projectId: 'test-portal-app',
  storageBucket: 'test-portal-app.appspot.com',
  messagingSenderId: '695781959167',
  appId: '1:695781959167:web:9f48760ec7d47f9226b3ca',
  measurementId: 'G-YYCZ61GVLE'
};
// const firebaseConfig = {
//   apiKey: 'AIzaSyD4QP6dSpZS6xvvrtXYtmz-wAu61wTTvwA',
//   authDomain: 'retro-demo-66d03.firebaseapp.com',
//   projectId: 'retro-demo-66d03',
//   storageBucket: 'retro-demo-66d03.appspot.com',
//   messagingSenderId: '321794855848',
//   appId: '1:321794855848:web:02e8c8d0b2f4382db9439d',
//   measurementId: 'G-85LF8HJR5V',
// };

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export default messaging;
