import apiHelper from './index';

const allUsers = async (
  page,
  params = { key: 'username', value: '' },
  spinner = false
) => {
  return await apiHelper.get(
    `account/users_list/v2?is_admin=false&is_active=true&page=${page}` +
      (params.value ? `&${params.key}=${params.value}` : ''),
    spinner
  );
};

const allRoles = async (spinner = true) => {
  return await apiHelper.get('account/roles/', spinner);
};

const addNewRole = async (payload, spinner = false) => {
  return await apiHelper.post('account/roles/', { name: payload });
};

const deleteUser = async (userEmail, spinner = true) => {
  return await apiHelper.remove(`account/update-delete/${userEmail}/`, spinner);
};

const getRoleUsers = async (
  role,
  page,
  params = { key: 'username', value: '' },
  spinner = false
) => {
  return await apiHelper.get(
    `account/users_list/v2?is_admin=false&is_active=true&role__name=${role}&is_org_switch=false&page=${page}` +
      (params.value ? `&${params.key}=${params.value}` : ''),
    spinner
  );
};
const switchedUsers = async (spinner = true) => {
  return await apiHelper.get(
    'account/users_list?is_admin=false&is_active=true&is_org_switch=true',
    spinner
  );
};

const updateUser = async (userEmail, payload, spinner = true) => {
  return await apiHelper.patch(
    `account/update-delete/${userEmail}/`,
    payload,
    spinner
  );
};

const verifyUser = async (id, spinner = true) => {
  const payload = {
    is_email_verified: true,
  };
  return await apiHelper.patch(
    `account/admin-verification-email/${id}/`,
    payload,
    spinner
  );
};

const getLockedUsersList = async (spinner = true) => {
  return await apiHelper.get('account/lockout_list-delete/', spinner);
};

const removeUserLock = async (id, spinner = true) => {
  return await apiHelper.remove(
    `account/lockout_list-delete/?user=${id}`,
    spinner
  );
};

export default {
  allUsers,
  allRoles,
  deleteUser,
  getRoleUsers,
  switchedUsers,
  updateUser,
  verifyUser,
  getLockedUsersList,
  removeUserLock,
  addNewRole,
};
