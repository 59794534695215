import ApiHelper from './index';

const fetchTasks = async (spinner = true) => {
  return await ApiHelper.get('organization/task/', spinner);
};

const fetchOrganizationTasks = async (queryParam, spinner = true) => {
  return await ApiHelper.get('organization/task/taskpage', spinner, queryParam);
};

const fetchAllTaskAdminPanel = async (query, spinner) => {
  return await ApiHelper.get('organization/tasks/admin', spinner, query);
};

const cloneTasks = async (payload, spinner = true) => {
  return await ApiHelper.post('generic/clone_task/', payload, spinner);
};

const fetchTrainedTasksByStatus = async (status, spinner = true) => {
  return await ApiHelper.get(
    `organization/task/get_all_trained_tasks_of_organization?train_status=${status}`,
    spinner
  );
};

const fetchTaskDetails = async (taskId, spinner = true) => {
  return await ApiHelper.get(`organization/task/${taskId}/`, spinner);
};
const addTask = async (payload, spinner = true) => {
  return await ApiHelper.post('organization/task/', payload, spinner);
};

const fetchTaskStatus = async (taskId, spinner = true) => {
  return await ApiHelper.get(`organization/trained_task/${taskId}`, spinner);
};

const updateTask = async (taskId, payload, spinner = true) => {
  return await ApiHelper.patch(
    `organization/task/${taskId}/`,
    payload,
    spinner
  );
};

const deleteTask = async (taskId, spinner = true) => {
  return await ApiHelper.patch(
    `organization/task/${taskId}/`,
    { is_deleted: true },
    spinner
  );
};

const fetchTaskObjects = async (taskId, spinner) => {
  return await ApiHelper.get(
    `organization/task/list_task_objects/${taskId}/`,
    spinner
  );
};

const fetchTaskPlaneObjects = async (taskId, spinner) => {
  return await ApiHelper.get(
    `organization/task/list_plane_objects/${taskId}/`,
    spinner
  );
};

const addTaskObject = async (payload, spinner) => {
  return await ApiHelper.post(
    `organization/task/task_object/`,
    payload,
    spinner
  );
};

const addPlaneObject = async (payload, spinner) => {
  return await ApiHelper.put(
    `organization/task/plane_objects/${payload.id}/`,
    payload,
    spinner
  );
};

const deletePlaneObject = async (id, spinner) => {
  return await ApiHelper.remove(
    `organization/task/plane_objects/${id}/`,
    spinner
  );
};

const createNewPlane = async (payload, spinner) => {
  return await ApiHelper.post(
    `organization/task/plane_objects/`,
    payload,
    spinner
  );
};

const updateTaskObject = async (objectId, payload, spinner = true) => {
  return ApiHelper.patch(
    `organization/task/task_object/${objectId}/`,
    payload,
    spinner
  );
};

const deleteTaskObject = async (objectId, spinner = true) => {
  return ApiHelper.remove(
    `organization/task/task_object/${objectId}/`,
    spinner
  );
};

const fetchTaskObjectRules = async (taskId, spinner) => {
  return await ApiHelper.get(
    `/organization/task/object_detection_rule/${taskId}/`,
    spinner
  );
};

const createTaskObjectRules = async (taskId, payload, spinner) => {
  return await ApiHelper.post(
    `/organization/task/object_detection_rule/${taskId}/`,
    payload,
    spinner
  );
};

const deleteTaskObjectRules = async (params, spinner) => {
  return await ApiHelper.remove(
    `/organization/task/object_detection_rule/delete/`,
    spinner,
    params
  );
};

const deleteMultipleObjects = async (payload, spinner = false) => {
  return await ApiHelper.patch(
    'organization/task/object_detection_rule/bulk_delete',
    payload,
    spinner
  );
};

const updateTaskObjectDetectionRule = async (ruleId, payload, spinner) => {
  return await ApiHelper.patch(
    `/organization/task/object_detection_rule/${ruleId}/`,
    payload,
    spinner
  );
};

const updateTrainedTask = async (id, payload, spinner = true) => {
  return await ApiHelper.patch(
    `organization/trained_task/${id}`,
    payload,
    spinner
  );
};

const stopTrainingOfTask = async (payload, spinner = true) => {
  return await ApiHelper.put('organization/train/stop/', payload, spinner);
};

const updateCloneTask = async (payload, spinner = true) => {
  return await ApiHelper.patch('/generic/clone_task/', payload, spinner);
};

const getTaskStaticObjectList = async (id, spinner = false) => {
  return await ApiHelper.get(
    `organization/task/list_task_objects/${id}/?is_static=${true}`,
    spinner
  );
};

const getProjectorRegions = async (id, spinner = false) => {
  return await ApiHelper.get(`generic/list_region_json/${id}/`, spinner);
};

const getTaskStepMediaObjects = async (taskId,spinner=false) => {
  return await ApiHelper.get(`organization/task/s3_objects/${taskId}/`, spinner);
}

const patchTaskLastUpdate = async (taskId)=>{
  return await ApiHelper.patch(`organization/task/last_update/${taskId}`)
}

export default {
  fetchTasks,
  fetchTrainedTasksByStatus,
  fetchTaskDetails,
  fetchTaskStatus,
  fetchTaskPlaneObjects,
  fetchTaskObjects,
  addTaskObject,
  updateTaskObject,
  deleteTaskObject,
  fetchTaskObjectRules,
  createTaskObjectRules,
  deleteTaskObjectRules,
  updateTaskObjectDetectionRule,
  addTask,
  updateTask,
  deleteTask,
  updateTrainedTask,
  fetchOrganizationTasks,
  fetchAllTaskAdminPanel,
  cloneTasks,
  updateCloneTask,
  addPlaneObject,
  createNewPlane,
  getTaskStaticObjectList,
  deletePlaneObject,
  stopTrainingOfTask,
  getProjectorRegions,
  deleteMultipleObjects,
  getTaskStepMediaObjects,
  patchTaskLastUpdate
};
