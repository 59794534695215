export const localStorageItems = [
  'id',
  'name',
  'role',
  'organization',
  'token',
  'timestamp',
  'hostURL',
  'poc',
  'isPolicyAccepted',
  'twk_625ce4597b967b11798b2ca8',
  'twk_token_625ce4597b967b11798b2ca8',
  'fcmToken',
  'email',
  'sessionEndTime',
  'sessionId',
  'sessionUser',
  'sessionKey',
  'refresh',
  'loglevel',
  'require_otp',
  'isOtpSetup'
];

export const itemsToKeep = [
  'hostURL'
]

export const removeUnwantedLocalStorageItems = () => {
  localStorageItems.forEach((item) => {
    if (!itemsToKeep.includes(item)) {
      localStorage.removeItem(item);
    }
  });
}