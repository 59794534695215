import apiHelper from './index';
import { getAuthConfig } from './config';

async function getAllDepartmentsOfOrg(orgName, spinner = true) {
  return await apiHelper.get(
    'organization/' + orgName + '/department/',
    spinner
  );
}

async function addNewOrganization(payload) {
  return await apiHelper.post('organization/', payload)
}

async function updateOrganization(orgName, payload) {
  return await apiHelper.patch(`organization/${orgName}/`, payload)
}

async function deleteOrganization(orgName) {
  return await apiHelper.remove(`organization/${orgName}/`)
}

async function createDepartment(orgName, departName, spinner = true) {
  return await apiHelper.post('organization/' + orgName + '/department/', {
    name: departName
  });
}

async function switchOrganization(payload) {
  return await apiHelper.patch(
    `organization/organization-switch`,
    payload,
    false
  );
}

const switchUser = async (userEmail, spinner = true) => {
  return await apiHelper.patch(
    `organization/organization-switch/${userEmail}/`,
    spinner,
    getAuthConfig()
  );
};

async function createKaizenBucket(payload) {
  return await apiHelper.post('organization/create_kaizen_bucket', payload);
}

async function getPaginatedOrganizations(query, spinner = true) {
  return await apiHelper.get(
    'organization/get_organizations?' + query,
    spinner
  );
}

export default {
  getAllDepartmentsOfOrg,
  createDepartment,
  switchOrganization,
  createKaizenBucket,
  switchUser,
  getPaginatedOrganizations,
  addNewOrganization,
  updateOrganization,
  deleteOrganization
};
