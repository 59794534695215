export const roles = {
  admin: 'admin',
  user: 'org_admin',
  org_user: 'org_user',
  labeler: 'labeler',
  analytics_user: 'analytics_user',
  workstation_user: 'workstation_user',
  support_user: 'support_user',
  doc_user: 'doc_user',
};

export const roleLabels = {
  [roles.admin]: 'Super Admin',
  [roles.user]: 'Organization Admin',
  [roles.org_user]: 'Organization User',
  [roles.labeler]: 'Labeler',
  [roles.analytics_user]: 'Analytics User',
  [roles.workstation_user]: 'Workstation User',
  [roles.support_user]: 'Support User',
  [roles.doc_user]: 'Docs User',
};

export const rolesDrawerItems = {
  [roles.admin]: [
    'Organizations',
    'All Users',
    'Roles',
    'Pending Users',
    'All Devices',
    'All Operations',
    'Archived Cycles',
    'Updates',
    'Instances',
    'Trained Operations',
    'Annotations',
    'Labelers',
    'Tampering Requests',
    'User Report',
    'Barcode',
    'AWS-Lambda Token',
    'Org Model Versions',
    'Model Types',
    'Feature Control',
    'Event Notification',
  ],
  [roles.user]: [
    'Home',
    'SETUP',
    'Add Device',
    'Define Operation',
    // 'IoT Pluginsᴮᴱᵀᴬ',
    'Record Training Set',
    'Label Training Set',
    'Train Model',
    'Deploy Model',
    // 'Ergonomics',
    'LEAN',
    'Analytics',
    'Line Analytics',
    'Trace',
    'Issue Center',
    'MANAGEMENT',
    'Devices',
    'Cameras',
    'Users',
    'Issue Center',
    'MANAGEMENT',
    'Devices',
    'Users',
    'OPC',
  ],
  [roles.org_user]: [
    'Home',
    'SETUP',
    'Add Device',
    'Define Operation',
    // 'IoT Pluginsᴮᴱᵀᴬ',
    'Record Training Set',
    'Label Training Set',
    'Train Model',
    'Deploy Model',
    // 'Ergonomics',
    'LEAN',
    'Analytics',
    'Line Analytics',
    'Trace',
    'Issue Center',
    'MANAGEMENT',
    'Devices',
    'Cameras',
    'OPC',
  ],
  [roles.labeler]: ['Tasks Requested', 'Profile Setting'],
  [roles.analytics_user]: [
    'Analytics',
    'Line Analytics',
    'Trace',
    'Issue Center',
  ],
  [roles.support_user]: [
    'Home',
    'SETUP',
    'Add Device',
    'Define Operation',
    // 'IoT Pluginsᴮᴱᵀᴬ',
    'Record Training Set',
    'Label Training Set',
    'Train Model',
    'Deploy Model',
    // 'Ergonomics',
    'LEAN',
    'Analytics',
    'Line Analytics',
    'Trace',
    'Issue Center',
    'MANAGEMENT',
    'Devices',
    'Cameras',
    'Users',
    'OPC',
  ],
  [roles.doc_user]: [
    'Home',
    'SETUP',
    'Add Device',
    'Define Operation',
    // 'IoT Pluginsᴮᴱᵀᴬ',
    'Record Training Set',
    'Label Training Set',
    'Train Model',
    'Deploy Model',
    // 'Ergonomics',
    'LEAN',
    'Analytics',
    'Line Analytics',
    'Trace',
    'Issue Center',
    'MANAGEMENT',
    'Devices',
    'Cameras',
    'Users',
  ],
};
