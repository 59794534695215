<template>
  <Loading v-if="getSpinner" />
  <router-view></router-view>
</template>

<script>
import { useToast } from 'vue-toastification';
import Loading from './components/core/Loading.vue';
import httpClient from './service/httpClient';
const deepClone = require('lodash.clonedeep');
import firebaseProject from 'src/config/firebase-current-project.json';

export default {
  name: 'App',
  components: { Loading },
  provide: {
    toast: useToast(),
    deepClone,
    showToast: (type, message, timeout = 3000) => {
      useToast()[type](message, { timeout });
    },
    isDev: () => {
      return firebaseProject['project-name'] === 'testing';
    }
  },
  data() {
    return {
      loading: false,
      sessionCheckDuration: 10000,
      sessionUpdateDuration: 20000,
      sessionTerminateDuration: 120000
    };
  },
  computed: {
    getSpinner() {
      return this.$store.state.spinner;
    }
  },
  mounted() {
    let $self = this;
    document.body.classList.add(
      'menu-position-side',
      'menu-side-left',
      'full-screen',
      'with-content-panel'
    );

    $self.customInterval($self);
  },
  unmounted() {
    document.body.classList.remove(
      'menu-position-side',
      'menu-side-left',
      'full-screen',
      'with-content-panel'
    );
  },
  methods: {
    async createSession(user) {
      const [browser, version]=this.getBrowserInfo()
      const res = await httpClient.post(
        'account/session/create/',
        { user: user, browser: browser, browser_version: version},
        false,
        false,
        false
      );
      if (res === 'error') {
        console.log('Error creating Session');
      }
      this.setLocal('sessionId', res.id);
      this.setLocal('sessionEndTime', res.end_time);
      this.setLocal('sessionUser', res.user);
    },

    getBrowserInfo() {
      const userAgent = navigator.userAgent;
      const browsers = [
        { name: "Microsoft Edge", keyword: "Edg" },
        { name: "Mozilla Firefox", keyword: "Firefox" },
        { name: "Samsung Internet", keyword: "SamsungBrowser" },
        { name: "Opera", keyword: "OPR" },
        { name: "Opera", keyword: "Opera" },
        { name: "Microsoft Internet Explorer", keyword: "Trident" },
        { name: "Google Chrome", keyword: "Chrome" },
        { name: "Apple Safari", keyword: "Safari" }
      ];

      for (const browser of browsers) {
        const keyword = browser.keyword;
        if (userAgent.includes(keyword)) {
          const versionMatch = userAgent.match(new RegExp(`${keyword}/([\\d.]+)`));
          const version = versionMatch ? versionMatch[1] : "Unknown version";
          console.log(version)
          return [browser.name, version];
        }
      }

      return ["Unknown", "Unknown"];
  },

    async updateSession(id) {
      const res = await httpClient.patch(
        `account/session/update/`,
        `${id}/`,
        {},
        false,
        false,
        false
      );
      if (res === 'error') {
        console.log('Error updating Session');
        return
      }
      this.setLocal('sessionEndTime', res.end_time);
    },

    customInterval(self) {
      setInterval(() => {
        const sessionId = self.getLocal('sessionId');
        const sessionEnd = self.getLocal('sessionEndTime');
        const sessionUser = self.getLocal('sessionUser');
        const currentUser = self.getLocal('id');

        // console.log(sessionId, sessionEnd, sessionUser, currentUser);

        if (!currentUser) {
          return;
        }

        if (document.hasFocus()) {
          if (!sessionId || sessionUser !== currentUser) {
            self.createSession(currentUser);
            console.log('session -> started');
          } else if (
            self.timeExceeded(sessionEnd, self.sessionUpdateDuration)
          ) {
            self.updateSession(sessionId);
            // console.log("session -> updated");
          }
        } else {
          if (
            sessionId &&
            self.timeExceeded(sessionEnd, self.sessionTerminateDuration)
          ) {
            self.setLocal('sessionId', null);
            console.log('session -> terminated');
          }
        }
      }, self.sessionCheckDuration);
    },

    getLocal(key) {
      const value = localStorage.getItem(key);
      try {
        return JSON.parse(value);
      } catch (err) {
        return value;
      }
    },

    setLocal(key, value) {
      localStorage.setItem(key, value);
    },

    timeExceeded(time, limit) {
      const now = new Date().getTime();
      const old = new Date(time).getTime();

      const diff = now - old;
      return diff > limit;
    }
  }
};
</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Lato:300,400,700';
@import '~vue-toastification/dist/index.css';
@import './assets/css/main.css?version=4.5.0';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import './assets/css/vue-popper.css';
@import '~ant-design-vue/dist/antd.css';

.black-color {
  color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.anticon {
  display: inline-flex !important;
}

.clickable {
  cursor: pointer !important;
}
.disable-click {
  pointer-events: none !important;
  color: lightgrey !important;
}
.full-modal .ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}
.full-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}
.full-modal .ant-modal-body {
  flex: 1;
}
</style>
