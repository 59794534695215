export default {
  turnOnSpinner(context) {
    context.commit('toggleSpinner', true);
  },

  turnOffSpinner(context) {
    context.commit('toggleSpinner', false);
  },
  changeRoute(context, value) {
    context.commit('changeRoute', value);
  },
  changeRouteConfig(context, object, self) {
    context.commit('changeRouteConfig', object, self);
  },
  setTaskId(context, value) {
    context.commit('setTaskId', value);
  },
  openSidebar(context) {
    context.commit('updateSidebarState', true);
  },
  closeSidebar(context) {
    context.commit('updateSidebarState', false);
  },

  updateSidebar(context, value) {
    context.commit('updateSidebarState', value);
  },

  setLightTheme(context) {
    context.commit('updateTheme', true);
  },
  setDarkTheme(context) {
    context.commit('updateTheme', false);
  },
  setCurrentObject(context, obj) {
    context.commit('setCurrentObject', obj);
  },
  startTour(context) {
    context.commit('startTour', true);
  },
  stopTour(context) {
    context.commit('stopTour', false);
  },
  resetState(context) {
    context.commit('resetState');
  },
  setPrevMetaData(context, value) {
    context.commit('setPrevMetaData', value);
  },
  setTotalFrames(context, value) {
    context.commit('setTotalFrames', value);
  },
  // setFrameStepSize(context, value) {
  //   context.commit('setFrameStepSize', value);
  // },
};
