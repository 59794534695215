import ApiHelper from './index';

async function calculatePlaneEquation(planeId, payload, spinner = true) {
  return await ApiHelper.put(
    `organization/task/calculate_plane_object_equation/${planeId}/`,
    payload,
    spinner
  );
}

async function getDepthVideoFrames(task_id, spinner = true) {
  return await ApiHelper.get(
    `organization/task/depth_frame/${task_id}/`,
    spinner
  );
}

async function generateDepthVideoFrames(task_id, payload, spinner = true) {
  return await ApiHelper.post(
    `organization/task/depth_frame/${task_id}/`,
    payload,
    spinner
  );
}

async function resetDepthVideoFrames(task_id, payload, spinner = true) {
  return await ApiHelper.put(
    `organization/task/reset_plane_objects/${task_id}/`,
    payload,
    spinner
  );
}

async function getZeroDepthVideoFrames(task_id, spinner = true) {
  return await ApiHelper.get(
    `organization/task/zero_depth_pixel/${task_id}/`,
    spinner
  );
}

async function isTaskSupport3D(task_id, spinner = true) {
  return await ApiHelper.get(`organization/task/is_3d/${task_id}/`, spinner);
}

async function getOverlayImage(task_id, planeId, spinner = true) {
  return await ApiHelper.get(
    `organization/task/get_overlay_frame/${task_id}/?plane_id=${planeId}`,
    spinner
  );
}

export default {
  calculatePlaneEquation,
  getDepthVideoFrames,
  generateDepthVideoFrames,
  isTaskSupport3D,
  getOverlayImage,
  getZeroDepthVideoFrames,
  resetDepthVideoFrames
};
