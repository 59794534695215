import { createStore } from 'vuex';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import modules from 'src/store/modules';

export default createStore({
  state() {
    return {
      spinner: false,
      sidebar: false,
      theme: true,
      currentRoute: [],
      currentObject: null,
      isTourRunning: false,
      routeDetail: {
        prevRouter: null,
        nextRoute: null,
      },
      taskId: null,
      prevMetaData: null,
      // frameStepSize: 10,
      totalFrames: 0,
    };
  },

  getters: getters,
  mutations: mutations,
  actions: actions,

  modules: {
    ...modules,
  },
});
