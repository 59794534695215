export const calculateUploadingSpeed = (
  event,
  initialStartTime,
  bitsUploaded
) => {
  var chunk = bitsUploaded;
  var time_started = initialStartTime;
  var estimated_time;

  const { loaded, total } = event;
  // calculate bytes uploaded
  var uploadedBytes = loaded - chunk;
  chunk = loaded;
  // Get number of seconds since last update
  var end_time = new Date().getTime();
  var elapsed = (end_time - time_started) / 1000;
  time_started = end_time;
  // Divide by elapsed time to get bytes per second
  var currentSpeed = (uploadedBytes / elapsed).toFixed(2);
  // Output upload speed
  // calculate remaining time in seconds
  var time = (total - uploadedBytes) / currentSpeed;
  if (elapsed) {
    estimated_time = Math.floor(time) * 1000;
  }
  return {
    last_update_time: time_started,
    estimated_time: estimated_time,
    chunk: chunk,
  };
};

export function formatSizeUnits(bytes, withUnit) {
  let unit = 'bytes';
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2);
    unit = ' G';
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2);
    unit = ' M';
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2);
    unit = ' K';
  } else if (bytes > 1) {
    bytes = bytes;
    unit = ' bytes';
  } else if (bytes == 1) {
    bytes = bytes;
    unit = ' byte';
  } else {
    bytes = '0 bytes';
  }
  var result = withUnit ? bytes + unit : bytes;
  return result;
}
