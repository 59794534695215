import { axiosInstance } from 'src/services/index';
import store from './../../store/index';
import { useToast } from 'vue-toastification';

let axios = axiosInstance;

export function get(
  baseUrl,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  const list = [];

  // if spinner is true
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }

  return axios
    .get(hostUrl + baseUrl, config)
    .then((response) => response.data)
    .then((response) => {
      // turn of the spinner
      store.dispatch('turnOffSpinner');

      // if log is true
      if (log) {
        // console.log("response data");
        // console.log(response);
      }

      // turn response format into a list
      Object.keys(response).forEach((value, key) => {
        list.push({
          ...response[value],
        });
      });

      // return a list
      return list;
    })
    .catch((error) => {
      console.log('error', error.response);
      store.dispatch('turnOffSpinner');
      if (!error.response) return 'error';
      if (error.response.status === 403) return error.response.status;
      return 'error';
    });
}

export function getData(
  baseUrl,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  const list = [];

  // if spinner is true
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }

  return axios
    .get(hostUrl + baseUrl, config)
    .then((response) => response.data)
    .then((response) => {
      // turn of the spinner
      if (spinner) {
        store.dispatch('turnOffSpinner');
      }

      // if log is true
      if (log) {
        // console.log("response data");
        // console.log(response);
      }

      // return response
      return response;
    })
    .catch((error) => {
      console.log('error', error.response);
      store.dispatch('turnOffSpinner');
      if (error.response.status === 403) return error.response.status;
      return 'error';
    });
}

export function post(
  baseUrl,
  data,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  const list = [];

  // if spinner is true
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }

  return axios
    .post(hostUrl + baseUrl, data, config)
    .then((response) => response.data)
    .then((response) => {
      if (alert) {
        useToast().success(successMessage, { timeout: 3000 });
      }
      // turn of the spinner
      store.dispatch('turnOffSpinner');

      // if log is true
      if (log) {
        // console.log("response data");
        // console.log(response);
      }

      // return response
      return response;
    })
    .catch((error) => {
      store.dispatch('turnOffSpinner');
      if (alert) {
        if (error?.response?.data?.msg || error[0] || errorMessage) {
          console.log("error catch: ",error.response.data);
          useToast().error(
            error?.response?.data?.msg || error[0] || errorMessage,
            { timeout: 3000 }
          );
        } else if (error.request) {
          useToast().error(error.request, { timeout: 3000 });
        } else {
          useToast().error(error.message, { timeout: 3000 });
        }
      }
      return error?.response?.data || 'error';
    });
}

//==============================================================================
export function startTraining(
  baseUrl,
  data,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage
) {
  // if spinner is true
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }
  return axios
    .post(hostUrl + baseUrl, data, config)
    .then((res) => res.data)
    .then((response) => {
      if (alert) {
        useToast().success(successMessage, { timeout: 3000 });
      }
      // turn of the spinner
      store.dispatch('turnOffSpinner');

      // if log is true
      if (log) {
        console.log('response data');
        console.log(response);
      }

      // return response
      return response;
    })
    .catch((err) => {
      useToast().error(err.response.data[0], { timeout: 3000 });
      store.dispatch('turnOffSpinner');
      return 'error';
    });
}
//==============================================================================

export function put(
  baseUrl,
  id,
  data,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  const list = [];

  // if spinner is true
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }
  return axios
    .put(hostUrl + baseUrl + id, data, config)
    .then((response) => response.data)
    .then((response) => {
      if (alert) {
        useToast().success(successMessage, { timeout: 3000 });
      }
      // turn of the spinner
      store.dispatch('turnOffSpinner');

      // if log is true
      if (log) {
        // console.log("response data");
        // console.log(response);
      }

      // return response
      return response;
    })
    .catch((error) => {
      if (alert) {
        useToast().error(errorMessage, { timeout: 3000 });
      }
      store.dispatch('turnOffSpinner');
      return 'error';
    });
}

export function patch(
  baseUrl,
  id,
  data,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  const list = [];

  // if spinner is true
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }
  return axios
    .patch(hostUrl + baseUrl + id, data, config)
    .then((response) => response.data)
    .then((response) => {
      if (alert) {
        useToast().success(successMessage, { timeout: 3000 });
      }
      // turn of the spinner
      store.dispatch('turnOffSpinner');

      // if log is true
      if (log) {
        // console.log("response data");
        // console.log(response);
      }

      // return response
      return response;
    })
    .catch((error) => {
      console.log('error');
      if (alert) {
        useToast().error(error?.response?.data?.detail || errorMessage, {
          timeout: 3000,
        });
      }
      store.dispatch('turnOffSpinner');
      return 'error';
    });
}

export function remove(
  baseUrl,
  id,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  const list = [];

  // if spinner is true
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }

  return axios
    .delete(hostUrl + baseUrl + id, { ...config })
    .then((response) => response.data)
    .then((response) => {
      if (alert) {
        useToast().success(successMessage, { timeout: 3000 });
      }
      // turn of the spinner
      store.dispatch('turnOffSpinner');

      // if log is true
      if (log) {
        // console.log("response data");
        // console.log(response);
      }

      // return response
      return response;
    })
    .catch((error) => {
      if (alert) {
        useToast().error(error.response?.data?.detail || errorMessage, {
          timeout: 3000,
        });
      }
      store.dispatch('turnOffSpinner');
      return 'error';
    });
}
