import { calculateUploadingSpeed, formatSizeUnits } from '../../utils/upload';

export const getConfig = () => {
  const header = { 'Content-Type': 'application/json', Accept: '*/*' };
  const config = { headers: header };
  return config;
};

export const getAuthConfig = () => {
  const token = localStorage.getItem('token');
  const auth = 'Bearer ' + token;
  const header = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: auth,
    SessionKey: localStorage.getItem('sessionKey'),
  };
  const config = { headers: header };
  return config;
};

export const getUploadConfig = (obj) => {
  const token = localStorage.getItem('token');
  const auth = 'Bearer ' + token;
  const header = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: auth,
    SessionKey: localStorage.getItem('sessionKey'),
  };
  var time_started = new Date().getTime();
  var previousBitsUploaded = 0;
  var remainingEstimatedTime;

  const config = {
    headers: header,
    onUploadProgress: (progressEvent) => {
      if (!obj) return;
      obj.isSlowSpeed = false;
      // calculate estimated time to upload data wrt up speed
      const {
        last_update_time,
        estimated_time,
        chunk,
      } = calculateUploadingSpeed(
        progressEvent,
        time_started,
        previousBitsUploaded
      );
      time_started = last_update_time;
      remainingEstimatedTime = estimated_time
        ? estimated_time
        : remainingEstimatedTime;
      previousBitsUploaded = chunk;
      // calculate file upload progress
      const { loaded, total } = progressEvent;
      let percent = parseInt(Math.round((loaded * 100) / total));
      let bitsUploaded = formatSizeUnits(loaded, true);
      let totalBits = formatSizeUnits(total, true);
      let text = `${bitsUploaded}B of ${totalBits}B`;
      // show result
      const objectKeys = Object.keys(obj);
      if (objectKeys.includes('progressText')) obj.progressText = text;
      if (objectKeys.includes('networkType'))
        obj.networkType = navigator.connection.effectiveType;
      obj.progress = percent;
    },
  };
  // set Time out for checking upload time
  console.log('time out ->', remainingEstimatedTime);
  setTimeout(() => {
    console.log('You have a slow internet');
    if (obj.progress < 100) obj.isSlowSpeed = true;
  }, remainingEstimatedTime || 100000);

  if (obj?.cancelToken) config['cancelToken'] = obj.cancelToken;
  return config;
};

export const testHostUrl = 'https://dev.retrocausal.tech/';
// export const prodHostUrl = 'https://dev.retrocausal.tech/';
export const prodHostUrl = 'https://backend.retrocausal.tech/';
export const localHostUrl = 'http://localhost:8000/';
