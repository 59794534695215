import ApiHelper from './index';

export const fetchArchivedCycles = async (spinner = true) => {
    return await ApiHelper.get('telemetry/archive_cycle/', spinner);
  };

export const createArchivedCycles = async (payload) => {
  return await ApiHelper.post('telemetry/archive_cycle/', payload);
}

export const restoreArchivedCycles = async (id) => {
  return await ApiHelper.remove(`telemetry/archived_cycle/${id}`);
}