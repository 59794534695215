import Highcharts from 'highcharts';

(function(H) {
  H.Legend.prototype.getAllItems = function() {
    var allItems = [];
    this.chart.series.forEach(series => {
      if (!series) return;
      var seriesOptions = series && series.options;
      if (series.type === 'xrange') {
        series.color = series.userOptions.color;
      }
      if (
        series &&
        H.pick(
          seriesOptions.showInLegend,
          !H.defined(seriesOptions.linkedTo) ? undefined : false,
          true
        )
      ) {
        allItems = allItems.concat(
          series.legendItems ||
            (seriesOptions.legendType === 'point' ? series.data : series)
        );
      }
    });

    H.fireEvent(this, 'afterGetAllItems', { allItems: allItems });

    return allItems;
  };
})(Highcharts);

export default Highcharts;
