<template>
  <icon :style="{ color }">
    <template #component>
      <component :is="CustomIcons[icon]" />
    </template>
  </icon>
</template>
<script>
import { defineComponent } from 'vue';
import Icon from '@ant-design/icons-vue';
import CustomIcons from 'src/assets/svg';

export default defineComponent({
  components: {
    Icon,
  },
  setup: () => ({
    CustomIcons,
  }),
  props: {
    color: {
      default: 'black',
      type: String,
    },
    icon: {
      default: 'InfoCircleIcon',
      type: String,
    },
  },
});
</script>
