import { roles } from 'src/config/roles-config.js';

export default [
  {
    path: '/user',
    name: 'User Layout',
    component: () => import('src/components/core/LayoutV2.vue'),
    children: [
      {
        path: 'temp',
        name: 'Temp',
        component: () =>
          import(
            'src/components/user-panel/pages/process/ObjectDetection/FileUploader.vue'
          ),
        meta: { authorize: [roles.user, roles.org_user] },
      },
      {
        path: 'home',
        name: 'Home',
        component: () =>
          import('src/components/user-panel/pages/home-page/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'process',
        name: 'Define Operation',
        component: () =>
          import('src/components/user-panel/pages/process/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'record',
        name: 'Record Training Set',
        component: () =>
          import('src/components/user-panel/pages/RecordData/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'train',
        name: 'Train Model',
        component: () =>
          import('src/components/user-panel/pages/train-model/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'deploy',
        name: 'Deploy Model',
        component: () =>
          import('src/components/user-panel/pages/DeployModel/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'models',
        name: 'Model Versions',
        component: () =>
          import('src/components/user-panel/pages/model-versions/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'tasks',
        name: 'Tasks',
        component: () =>
          import('src/components/user-panel/pages/tasks/TaskList'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'devices',
        name: 'Device',
        component: () =>
          import('src/components/user-panel/pages/devices/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
        children: [
          {
            path: '',
            name: 'Devices',
            component: () =>
              import('src/components/user-panel/pages/devices/MainPage.vue'),
            meta: {
              authorize: [roles.user, roles.org_user, roles.support_user],
            },
          },
          {
            path: ':id',
            name: 'DeviceDetail',
            component: () =>
              import(
                'src/components/user-panel/pages/devices/DeviceDetailPage/DeviceDetailPage.vue'
              ),
            meta: {
              authorize: [roles.user, roles.org_user, roles.support_user],
            },
          },
        ],
      },
      {
        path: 'opc',
        name: 'OPC',
        component: () =>
          import(
            'src/components/user-panel/pages/IoT/OPCConnectionMenuBar.vue'
          ),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'cameras',
        name: 'Cameras',
        component: () =>
          import('src/components/user-panel/pages/RemoteDevices/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: () =>
          import('src/components/user-panel/pages/analytics/Index.vue'),
        meta: {
          authorize: [
            roles.user,
            roles.org_user,
            roles.analytics_user,
            roles.support_user,
          ],
        },
      },
      {
        path: 'line-analytics',
        name: 'Line Analytics',
        component: () =>
          import('src/components/user-panel/pages/LineAnalytics/Index.vue'),
        meta: {
          authorize: [
            roles.user,
            roles.org_user,
            roles.analytics_user,
            roles.support_user,
          ],
        },
      },
      {
        path: 'trace/:traceId?',
        name: 'Trace',
        component: () =>
          import('src/components/user-panel/pages/trace/Index.vue'),
        meta: {
          authorize: [
            roles.user,
            roles.org_user,
            roles.analytics_user,
            roles.support_user,
          ],
        },
      },
      {
        path: 'org-users',
        name: 'Users',
        component: () =>
          import('src/components/user-panel/pages/Users/Index.vue'),
        meta: { authorize: [roles.user, roles.support_user] },
      },
      {
        path: 'setting',
        name: 'Profile Settings',
        component: () =>
          import('src/components/user-panel/pages/profile-setting/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'user-manual',
        name: 'User Manuals',
        component: () =>
          import('src/components/user-panel/pages/user-manuals/Index.vue'),
        meta: {
          authorize: [
            roles.user,
            roles.org_user,
            roles.doc_user,
            roles.support_user,
          ],
        },
      },
      {
        path: 'fundamentals',
        name: 'App Fundamentals',
        component: () =>
          import('src/components/user-panel/pages/app-fundamental/Index.vue'),
        meta: {
          authorize: [
            roles.user,
            roles.org_user,
            roles.doc_user,
            roles.support_user,
          ],
        },
      },
      {
        path: 'add-device',
        name: 'Add Device',
        component: () =>
          import('src/components/user-panel/pages/AddDevice/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'label-data',
        name: 'Label Training Set',
        component: () =>
          import('src/components/user-panel/pages/LabelData/Index.vue'),
        meta: { authorize: [roles.user, roles.org_user, roles.support_user] },
      },
      {
        path: 'issue-center',
        name: 'Issue Center',
        component: () =>
          import('src/components/user-panel/pages/obeya-room/Index.vue'),
        meta: {
          authorize: [
            roles.user,
            roles.org_user,
            roles.analytics_user,
            roles.support_user,
          ],
        },
      },
    ],
  },
];
