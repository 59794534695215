import types from '../mutation-types';
import BinaryDeploymentServices from '../../services/binaryDevelopments';
import dateHelper from '../../components/shared/Helpers/dateHelper.js';

import { useToast } from 'vue-toastification';

const toast = useToast();

const state = {
  releases: [],
  isLoading: false,
  latestBranchId: {},
  latestDate: {},
  latestDeployment: {},
  currentPage: 1,
  totalResult: 0,
  latestRetroGuiDevBranch: {},
};

const getters = {
  releases: (state) => state.releases,
  isLoading: (state) => state.isLoading,
  latestBranchId: (state) => state.latestBranchId,
  latestDate: (state) => state.latestDate,
  latestDeployment: (state) => state.latestDeployment,
  currentPage: (state) => state.currentPage,
  totalResult: (state) => state.totalResult,
  latestRetroGuiDevBranch: (state) => state.latestRetroGuiDevBranch,
};

const actions = {
  async getPreviousReleases({ commit }) {
    commit(types.SET_IS_LOADING, true);
    const [error, data] = await BinaryDeploymentServices.getPreviousReleases(
      state.currentPage
    );
    commit(types.SET_IS_LOADING, false);
    if (error) {
      console.log({ error });
      return;
    }
    const count = data.count;

    const releases = data.results.map((el) => ({
      ...el,
      start_time: dateHelper.formatDate(el.start_time),
      end_time: dateHelper.formatDate(el.end_time),
    }));

    let latestDate = state.latestDate;
    let latestBranchId = state.latestBranchId;

    data.results.forEach((obj) => {
      const objectKey = `${obj.running_environment}-${obj.branch}`;
      const objDate = new Date(obj.end_time);
      const latestBranchDate = latestDate[objectKey] || new Date('2000-01-01');
      if (objDate >= latestBranchDate) {
        latestBranchId[objectKey] = obj.id;
        latestDate[objectKey] = objDate;
      }
    });

    commit(types.SET_RELEASES, releases);
    commit(types.SET_RELEASES_COUNT, count);
    commit(types.SET_LATEST_DATE, latestDate);
    commit(types.SET_LATEST_BRANCH_ID, latestBranchId);
  },

  async downloadBinaries({ commit, dispatch }, record) {
    let latestBranchId = state.latestBranchId;
    const isLatestBranch =
      latestBranchId[`${record.running_environment}-${record.branch}`] ===
      record.id;
    commit(types.SET_IS_LOADING, true);
    const [error, data] = await BinaryDeploymentServices.downloadBinaries(
      record.running_environment,
      record.branch,
      record.version_number,
      isLatestBranch
    );
    commit(types.SET_IS_LOADING, false);
    if (error) {
      console.log({ error });
      toast.error('Could Not Download The Binaries');
      return;
    }
    window.open(data.link, '_blank');
  },

  async getLatestDeployment({ commit, dispatch }, env) {
    commit(types.SET_IS_LOADING, true);
    const [error, data] = await BinaryDeploymentServices.getLatestDeployment(
      env
    );
    commit(types.SET_IS_LOADING, false);
    if (error) {
      console.log({ error });
      toast.error('Could Not Download The Binaries');
      return;
    }
    const latestDeployment = {
      show: true,
      env: data.running_environment,
      version: data.version_number,
      branch: data.branch,
      status: data.currentState,
    };
    commit(types.SET_LATEST_DEPLOYMENT, latestDeployment);
  },

  async getLatestDeployableBranch({ commit }) {
    commit(types.SET_IS_LOADING, true);
    const [
      error,
      data,
    ] = await BinaryDeploymentServices.getLatestDeployableBranch();
    commit(types.SET_IS_LOADING, false);
    if (error) {
      console.log({ error });
      return;
    }
    const latestRetroGuiDevBranch = {
      env: data.running_environment,
      version: data.version_number,
      branch: data.branch,
      status: data.currentState,
    };
    commit(types.SET_LATEST_RETRO_GUI_BRANCH_DEV, latestRetroGuiDevBranch);
  },

  async deleteBranchFromAWS({ commit, dispatch }, delete_record) {
    commit(types.SET_IS_LOADING, true);
    console.log(
      `In Stores: ${delete_record.running_environment} ${delete_record.branch} ${delete_record.id}`
    );
    const [error, data] = await BinaryDeploymentServices.deleteBranchFromAWS(
      delete_record.running_environment,
      delete_record.branch,
      delete_record.id
    );
    commit(types.SET_IS_LOADING, false);
    if (error) {
      console.log({ error });
      return;
    }
    toast.success(data.body);
    dispatch('getPreviousReleases');
  },

  async moveBranchToDev({ commit, dispatch }, payload) {
    commit(types.SET_IS_LOADING, true);
    const [error, data] = await BinaryDeploymentServices.moveBranchToDev(
      payload.version,
      payload.env
    );
    commit(types.SET_IS_LOADING, false);
    if (error) {
      console.log({ error });
      toast.error(data.error);
      return;
    }
    toast.success(data.body);
    dispatch('getPreviousReleases');
  },

  setCurrentPage({ commit, dispatch }, pageNumber) {
    commit(types.SET_CURRENT_PAGE_RELEASES, pageNumber);
    dispatch('getPreviousReleases');
  },
};

const mutations = {
  [types.SET_RELEASES](state, payload) {
    state.releases = payload;
  },
  [types.SET_IS_LOADING](state, payload) {
    state.isLoading = payload;
  },
  [types.SET_LATEST_BRANCH_ID](state, payload) {
    state.latestBranchId = payload;
  },
  [types.SET_LATEST_DATE](state, payload) {
    state.latestDate = payload;
  },
  [types.SET_LATEST_DEPLOYMENT](state, payload) {
    state.latestDeployment = payload;
  },
  [types.SET_CURRENT_PAGE_RELEASES](state, payload) {
    state.currentPage = payload;
  },
  [types.SET_RELEASES_COUNT](state, payload) {
    state.totalResult = payload;
  },
  [types.SET_LATEST_RETRO_GUI_BRANCH_DEV](state, payload) {
    state.latestRetroGuiDevBranch = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
