import { roles } from 'src/config/roles-config.js';

export default [
  {
    path: '/labeler',
    name: 'Labeler Layout',
    component: () => import('src/components/core/LayoutV2.vue'),
    children: [
      {
        path: 'tasks',
        name: 'Tasks Requested',
        component: () =>
          import('src/components/labeler-panel/pages/tasks/Index.vue'),
        meta: { authorize: [roles.labeler] },
      },
      {
        path: 'setting',
        name: 'Profile Setting',
        component: () =>
          import(
            'src/components/labeler-panel/pages/profile-setting/Index.vue'
          ),
        meta: { authorize: [roles.labeler] },
      },
      {
        path: 'videos/:organization/:taskName/:taskId',
        name: 'Videos List',
        props: true,
        component: () =>
          import('src/components/labeler-panel/pages/VideosList/Index.vue'),
        meta: { authorize: [roles.labeler] },
      },
    ],
  },
];
