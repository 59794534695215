export const getUpdateAssociateBarcode = (
  allBarcodes,
  associateBarcodes,
  data
) => {
  const index = associateBarcodes.findIndex((el) => el.id === data.id);
  const updatedData = {
    ...data,
    barcode: allBarcodes[data.barcode],
    barcode_id: data.barcode,
  };
  associateBarcodes[index] = updatedData;
  return [...associateBarcodes];
};
export const getBarcodesDictionary = (barcodes) => {
  return barcodes.reduce((res, el) => {
    res[el.id] = el.barcode_value;
    return res;
  }, {});
};

export const getRemoveAssociatedBarcodes = (associateBarcodes, removeId) => {
  const filteredAssociatedBarcodes = associateBarcodes.filter(
    (el) => el.id !== removeId
  );
  return filteredAssociatedBarcodes;
};
