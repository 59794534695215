import { useToast } from 'vue-toastification';
import types from '../mutation-types';
import ModelVersionService from '@/services/modelVersions';

const toast = useToast();

const state = () => ({
  modelVersionsList: [],
  isFetchingModelVersions: false,
  isUpdatingModelVersion: false,
  isDeletingModelVersion: false,
  showUploadModelButton: false,
});

const getters = {
  modelVersionsList: (state) => state.modelVersionsList,
  isFetchingModelVersions: (state) => state.isFetchingModelVersions,
  isUpdatingModelVersion: (state) => state.isUpdatingModelVersion,
  isDeletingModelVersion: (state) => state.isDeletingModelVersion,
  showUploadModelButton: (state) => state.showUploadModelButton,
};

const actions = {
  async fetchModelVersionsList({ commit }, taskId) {
    commit(types.SET_IS_FETCHING_MODEL_VERSIONS, true);
    const [error, data] = await ModelVersionService.getModelVersionList(
      { task: taskId },
      false
    );
    commit(types.SET_IS_FETCHING_MODEL_VERSIONS, false);
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_MODEL_VERSIONS_LIST, data);
    commit(types.SET_SHOW_UPLOAD_MODEL_BUTTON, true);
  },

  createModelVersion({ state, commit }, payload) {
    return new Promise(async (resolve, _) => {
      const { modelVersionsList } = state;
      const [error, data] = await ModelVersionService.createModelVersion(
        payload
      );
      if (error) {
        console.log({ error });
        return;
      }
      commit(types.SET_MODEL_VERSIONS_LIST, [...modelVersionsList, data]);
      resolve();
    });
  },

  async toggleDelete({ state, commit }, record) {
    const { id, is_deleted } = record;
    const { modelVersionsList } = state;
    commit(types.SET_IS_DELETING_MODEL_VERSION, true);
    const prevMVList = [...modelVersionsList];

    const currentMVList = modelVersionsList.map((mv) => {
      if (mv.id === id) mv.is_deleted = !is_deleted;
      return mv;
    });
    commit(types.SET_MODEL_VERSIONS_LIST, currentMVList);

    const [error, _] = await ModelVersionService.deleteModelVersion(
      id,
      false,
      !is_deleted
    );
    commit(types.SET_IS_DELETING_MODEL_VERSION, false);
    if (error) {
      commit(types.SET_MODEL_VERSIONS_LIST, prevMVList);
      toast.error('Unable to delete Model Version');
      return;
    }
  },

  async updateModelVersion({ state, commit }, { modelVersionId, payload }) {
    const { modelVersionsList } = state;
    commit(types.SET_IS_UPDATING_MODEL_VERSION, true);
    const [error, response] = await ModelVersionService.updateModelVersion(
      modelVersionId,
      payload,
      false
    );
    commit(types.SET_IS_UPDATING_MODEL_VERSION, false);
    if (error) {
      console.log('error in updating model', error);
      return false;
    }
    const updatedModelVersions = modelVersionsList.map((modelVersion) => {
      if (modelVersion.id == modelVersionId) {
        return { ...response };
      } else return modelVersion;
    });
    commit(types.SET_MODEL_VERSIONS_LIST, updatedModelVersions);
    return true;
  },

  clearModelVersionsList({ commit }) {
    commit(types.SET_MODEL_VERSIONS_LIST, []);
    commit(types.SET_SHOW_UPLOAD_MODEL_BUTTON, false);
  },
};

const mutations = {
  [types.SET_MODEL_VERSIONS_LIST](state, payload) {
    state.modelVersionsList = payload;
  },
  [types.SET_IS_FETCHING_MODEL_VERSIONS](state, payload) {
    state.isFetchingModelVersions = payload;
  },
  [types.SET_IS_UPDATING_MODEL_VERSION](state, payload) {
    state.isUpdatingModelVersion = payload;
  },
  [types.SET_IS_DELETING_MODEL_VERSION](state, payload) {
    state.isDeletingModelVersion = payload;
  },
  [types.SET_SHOW_UPLOAD_MODEL_BUTTON](state, payload) {
    state.showUploadModelButton = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
