const types = {
    SET_TASK_OBJECT_LIST: "SET_TASK_OBJECT_LIST"
}

const state = {
    taskObjectList: []
}

const getters = {
    taskObjectList: (state) => state.taskObjectList
}

const actions = {
    setTaskObjectList({commit}, payload){
        commit(types.SET_TASK_OBJECT_LIST, payload)
    },
}

const mutations = {
    [types.SET_TASK_OBJECT_LIST](state, payload){
        state.taskObjectList = payload
    }
}

export default {
    state, getters, actions, mutations
}