import { axiosInstance } from 'src/services/index';
import store from './../../store/index';
import { useToast } from 'vue-toastification';
let axios = axiosInstance;
export function upload(
  baseUrl,
  data,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  // if spinner is true
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }

  store.dispatch('turnOnSpinner');
  return axios
    .post(hostUrl + baseUrl, data, config)
    .then((response) => {
      // if (alert) {
      //   useToast().success(successMessage);
      // }

      // turn of the spinner
      store.dispatch('turnOffSpinner');

      // if log is true
      if (log) {
        console.log('response data');
        console.log(response);
      }

      return response;
    })
    .catch((e) => showError(e, errorMessage, alert));
}

function showError(e, errorMessage, alert) {
  store.dispatch('turnOffSpinner');
  if (e.message && e.response?.status !== 409) {
    useToast().error(e.message);
    return 'error';
  }
  if (alert) {
    if (e.response?.status === 409 || e.response?.status === 404) {
      try {
        const msg =
          Object.keys(e.response.data)[0] +
          ': ' +
          Object.values(e.response.data)[0].join(',');
        useToast().error(msg);
      } catch (error) {
        useToast().error(errorMessage);
      }
    } else {
      useToast().error(errorMessage);
    }
  }
  return 'error';
}
