import * as ArchivedCycleService from 'src/services/archivedCycle';
import types from '../mutation-types';

const state = {
  fetchedCycle:[],
  archivedCycles: [],
  showAddModelArchivedCycleModal: false,
};

const getters = {
  showAddModelArchivedCycleModal: (state) => state.showAddModelArchivedCycleModal,
  archivedCycles: (state) => state.archivedCycles,
};

const actions = {
  async fetchArchivedCycles({ commit }) {
    const [error, data] = await ArchivedCycleService.fetchArchivedCycles();
    if (error) {
      console.log({ error });
      return;
    }
    console.log('data: ', data)
    commit(types.SET_ARCHIVED_CYCLE_LIST, data);
  },

  async filterArchivedCycles({ commit, state }, keyword) { // Accept keyword
    const filteredCycles = state.fetchedCycle.filter(cycle => 
      cycle.organization.toLowerCase().includes(keyword) ||
      cycle.task.toLowerCase().includes(keyword) ||
      cycle.device.toLowerCase().includes(keyword)
    );
    commit(types.FILTER_ARCHIVED_CYCLE_LIST, filteredCycles);
  },

  async createArchivedCycles({ commit }, payload){
    const [error, data] = await ArchivedCycleService.createArchivedCycles(payload);
    if (error) {
      console.log({ error });
      return;
    }
    // commit(types.SET_ARCHIVED_CYCLE_LIST, data);
  },

  async restoreArchivedCycles({ commit }, id){
    const [error, data] = await ArchivedCycleService.restoreArchivedCycles(id);
    if (error) {
      console.log({ error });
      return;
    }
    // commit(types.SET_ARCHIVED_CYCLE_LIST, data);
  },

  setShowAddModelArchivedCycleModal({ commit }, payload) {
    commit(types.SET_SHOW_ADD_MODEL_ARCHIVED_CYCLE, payload);
  },
};

const mutations = {
  [types.SET_SHOW_ADD_MODEL_ARCHIVED_CYCLE](state, payload) {
    state.showAddModelArchivedCycleModal = payload;
  },

  [types.FILTER_ARCHIVED_CYCLE_LIST](state, payload) {
    let mappedCycles = payload.map(data => {
      return {
        id: data.id,
        organization: data.organization,
        task: data.task,
        device: data.device,
        start_datetime: data.start_datetime,
        end_datetime: data.end_datetime
      }
    })
    state.archivedCycles = mappedCycles;
  },

  [types.SET_ARCHIVED_CYCLE_LIST](state, payload) {
    let mappedCycles = payload.map(data => {
      return {
        id: data.id,
        organization: data.organization,
        task: data.task.taskName,
        device: data.device.display_name || data.device.Serial_number,
        start_datetime: data.start_datetime,
        end_datetime: data.end_datetime
      }
    })
    state.archivedCycles = mappedCycles;
    state.fetchedCycle = mappedCycles;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};