import ApiHelper from './index';

const fetchUserPoolConfig = async (spinner = true) => {
  return await ApiHelper.get('generic/userpool/', spinner);
};
const createAwsLambdaToken = async (payload,spinner = true) => {
  return await ApiHelper.post('generic/userpool/', payload, spinner);
};

export default {
  fetchUserPoolConfig,
  createAwsLambdaToken
};
