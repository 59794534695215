export const actions = {
  updateTask: 'update_task',
  imageDetector: 'sample_images_for_detector',
  cloneTask: 'cloning_task',
  trimVideo: 'trim_video',
};

export const queues = {
  longRunningTask: 'long_running_service_queue',
  devQueue: 'mudassir-berry-queue',
};

export const statuses = {
  started: 'started',
  finished: 'finished',
};
