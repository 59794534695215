import types from '../mutation-types';
import cycleTypes from '../../config/cycle-type-config';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import TelemetryService from "@/services/telemetry";

const state = {
  selectedCycleType: cycleTypes['All Cycles'],
  cycleTypes: cycleTypes,
  isFetchingCycles: false,
  isFetchingTrace: false,
  showCompareAnalytics: false,
  dateRangeForComparison: [dateHelper._getDate(), dateHelper._getDate()],
  isFetchingAnalyticsForComparison: false,
  isFetchingMetaData: false,
  metaData: [],
};

const getters = {
  cycleTypes: state => state.cycleTypes,
  selectedCycleType: state => state.selectedCycleType,
  isFetchingCycles: state => state.isFetchingCycles,
  isFetchingTrace: state => state.isFetchingTrace,
  showCompareAnalytics: state => state.showCompareAnalytics,
  dateRangeForComparison: state => state.dateRangeForComparison,
  isFetchingAnalyticsForComparison: state =>
    state.isFetchingAnalyticsForComparison,
  isFetchingMetaData: state => state.isFetchingMetaData,
  metaData: state => state.metaData,
};

const actions = {
  setSelectedCycleType({ commit }, payload) {
    commit(types.SET_SELECTED_CYCLE_TYPE, payload);
  },

  setIsFetchingCycles({ commit }, payload) {
    commit(types.SET_IS_FETCHING_CYCLES, payload);
  },

  setIsFetchingMetaData({ commit }, payload) {
    commit(types.SET_IS_FETCHING_META_DATA, payload);
  },

  async setMetaData({commit}, payload) {
    commit(types.SET_IS_FETCHING_META_DATA, true);
    const [error, data] = await TelemetryService.fetchMetaData(
        payload
    );
    commit(types.SET_IS_FETCHING_META_DATA, false);
    if (error) {
      console.log({error});
      return;
    }
    commit(types.SET_META_DATA, data);
  },

  setIsFetchingTrace({ commit }, payload) {
    commit(types.SET_IS_FETCHING_TRACE, payload);
  },

  setShowCompareAnalytics({ commit }, payload) {
    commit(types.SHOW_COMPARE_ANALYTICS, payload);
  },
  setDateRangeForComparison({ commit }, payload) {
    commit(types.SET_DATE_RANGE_FOR_COMPARISON, payload);
  },
  setIsFetchingAnalyticsForComparison({ commit }, payload) {
    commit(types.SET_IS_FETCHING_ANALYTICS_FOR_COMPARISON, payload);
  }
};

const mutations = {
  [types.SET_SELECTED_CYCLE_TYPE](state, payload) {
    state.selectedCycleType = payload;
  },
  [types.SET_IS_FETCHING_CYCLES](state, payload) {
    state.isFetchingCycles = payload;
  },
  [types.SET_IS_FETCHING_META_DATA](state, payload) {
    state.isFetchingMetaData = payload;
  },
  [types.SET_META_DATA](state, payload) {
    state.metaData = payload;
  },
  [types.SET_IS_FETCHING_TRACE](state, payload) {
    state.isFetchingTrace = payload;
  },
  [types.SHOW_COMPARE_ANALYTICS](state, payload) {
    state.showCompareAnalytics = payload;
  },
  [types.SET_DATE_RANGE_FOR_COMPARISON](state, payload) {
    state.dateRangeForComparison = payload;
  },
  [types.SET_IS_FETCHING_ANALYTICS_FOR_COMPARISON](state, payload) {
    state.isFetchingAnalyticsForComparison = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
