import { deepClone } from 'src/utils/task';
import types from '../mutation-types';
import {
  extras,
  kaizenOptions,
  retrievalAugmentedOptions,
} from 'src/config/kaizen-copilot-config';

const state = {
  messages: [
    //     [
    //       {
    //         type: 'text',
    //         caption: `
    // Here are some insights on the **NETGEAR assembly** process:
    // **Cycle time**: The average cycle time observed during the period was 223 seconds, which is more than double the expected cycle time of 100 seconds. This indicates that there is room for improvement in the process to reduce cycle time and increase efficiency.
    // **Value-add percentage**: The worker was able to add value to the product only 30.22% of the time during each cycle. This indicates that there are some non-value adding activities that are taking up a significant amount of time in the process, which need to be identified and eliminated or reduced.
    // **Number of cycles**: The worker was only able to finish 31% of the expected number of cycles during the period. This indicates that the worker may be facing challenges such as lack of training, tools or resources, or may be encountering bottlenecks in the process, which are preventing them from completing the work as expected.
    // **Cycle time distribution**: The cycle time distribution has a significant number of cycles (41 out of 96) took more than 270 seconds to complete. This indicates that there may be some specific tasks or steps in the process that are taking a long time to complete, and may need to be analyzed and optimized to improve the overall cycle time and efficiency.
    // The most problematic steps in the NETGEAR assembly process are steps 6, 11, 13, 14, 15, and 16, which have a high percentage of steps missed and a large standard deviation of actual completion time.
    // `,
    //         is_advisor: true,
    //       },
    //     ],
    //     [
    //       {
    //         type: 'video',
    //         caption: 'Step 6',
    //         url: 'tighten_top_bottom_screws.mp4',
    //         is_advisor: true,
    //       },
    //     ],
    //     [
    //       {
    //         type: 'image',
    //         caption: 'Standard deviation on the step timings:',
    //         url: 'std_dev_single_duration.png',
    //         is_advisor: true,
    //       },
    //     ],
    //     [
    //       {
    //         type: 'link',
    //         caption: 'Click on the link to see the file.',
    //         links: [
    //           {
    //             label: 'Process_Capability_NETGEAR_Obj_V1.xls',
    //             value: 'Process_Capability_NETGEAR_Obj_V1.pdf',
    //           },
    //           {
    //             label: 'PVC_Amedeus_plant_6_July_2019.xls',
    //             value: 'PVC_Amedeus_plant_6_July_2019.xls',
    //           },
    //           {
    //             label: 'Power_cable_Kunal_February_2020.xls',
    //             value: 'Power_cable_Kunal_February_2020.xls',
    //           },
    //           {
    //             label: 'Recycling_plastic_cabling_v5_2019.xls',
    //             value: 'Recycling_plastic_cabling_v5_2019.xls',
    //           },
    //         ],
    //         is_advisor: true,
    //       },
    //     ],
  ],
  kaizenOptions,
  retrievalAugmentedOptions,
  currentKaizenOption: kaizenOptions[0],
  selectedSheet: null,
  kaizenExtras: extras,
};

const getters = {
  messages: (state) => state.messages,
  currentMessageIndex: (state) => state.messages.length,
  kaizenOptions: (state) => state.kaizenOptions,
  retrievalAugmentedOptions: (state) => state.retrievalAugmentedOptions,
  currentKaizenOption: (state) => state.currentKaizenOption,
  selectedSheet: (state) => state.selectedSheet,
  isRetrievalAugmented: (state) =>
    state.kaizenOptions[5] === state.currentKaizenOption,
  kaizenExamples: (state) =>
    state.kaizenExtras.examples.items[state.currentKaizenOption],
  kaizenLimitations: (state) => state.kaizenExtras.limitations,
};

const actions = {
  addMessage({ commit }, payload) {
    commit(types.SET_MESSAGES, [...state.messages, payload]);
  },

  addMessageToSpecificIndex({ commit }, payload) {
    const { is_advisor, currentMessageIndex, message } = payload;
    const temp = [...state.messages];

    if (!temp[currentMessageIndex]) {
      temp[currentMessageIndex] = [];
    }

    temp[currentMessageIndex] = [
      { type: 'text', caption: message, is_advisor },
    ];
    commit(types.SET_MESSAGES, temp);
  },

  setCurrentKaizenOption({ commit }, payload) {
    commit(types.SET_MESSAGES, []);
    commit(types.SET_CURRENT_KAIZEN_OPTION, payload);
  },

  setSelectedSheet({ commit }, payload) {
    commit(types.SET_SELECTED_SHEET, payload);
  },

  updateKaizenExamples({ commit, state }, payload) {
    const temp = deepClone(state.kaizenExtras);
    temp.examples.items[state.currentKaizenOption] = [...payload];
    commit(types.SET_KAIZEN_EXTRAS, temp);
  },
};

const mutations = {
  [types.SET_MESSAGES](state, payload) {
    state.messages = payload;
  },
  [types.SET_CURRENT_KAIZEN_OPTION](state, payload) {
    state.currentKaizenOption = payload;
  },
  [types.SET_SELECTED_SHEET](state, payload) {
    state.selectedSheet = payload;
  },
  [types.SET_KAIZEN_EXTRAS](state, payload) {
    state.kaizenExtras = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
