export const modes = {
  addDelete: 'add-delete',
  rename: 'rename',
  merge: 'merge',
};

export const taskCreationCardTitle = {
  [modes.addDelete]: '(Add/Delete Steps, Rename on Next Page)',
  [modes.rename]: '(Rename Steps, Merge Steps on Next Page)',
  [modes.merge]: '(Merge Steps)',
};

export const nextPrevButtonMap = {
  [modes.addDelete]: 'Next',
  [modes.rename]: 'Back',
};

export const taskUpdateJsonPhaseConfig = {
  [modes.addDelete]: 'is_step_added_and_deleted',
  [modes.rename]: 'is_step_renamed',
  [modes.merge]: 'is_step_merged',
};
