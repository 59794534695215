// import { useToast } from 'vue-toastification';
// import types from '../mutation-types';
// import TaskService from 'src/services/tasks';
// import {
//   validateForSpecialCharacters,
//   checkIfTaskExist,
//   checkIfEmpty,
//   checkIfSubstepsExists,
//   isDuplicateStep,
//   isDuplicateSubstep,
//   hasNoProcess,
//   hasNoSteps,
//   getTaskString,
//   startTaskModification,
//   getStepsCount,
// } from 'src/utils/task';

// const getDestinationIndex = (index, direction) => {
//   if (direction === 'up') return index - 1;
//   else return index + 1;
// };

// const toast = useToast();

// const state = {
//   newTask: 'dfvd',
//   taskEdit: '',
//   processes: [{ name: 'sdcs', steps: [{ name: 'sdsd', substeps: ['sdsd'] }] }],
//   processesTranslation: [],
//   currentEditMode: '',
//   editModes: {
//     addDelete: 'add-delete',
//     rename: 'rename',
//   },
//   prevStepsJson: {},
//   updatedJsonPayload: [],
//   taskUpdateConfirmModal: true,
// };

// const getters = {
//   newTask: (state) => state.newTask,
//   processes: (state) => state.processes,
//   processesTranslation: (state) => state.processesTranslation,
//   taskUpdateConfirmModal: (state) => state.taskUpdateConfirmModal,

//   steps: (state) => (processIndex) => state.processes[processIndex]['steps'],

//   stepsTranslation: (state) => (processIndex) =>
//     state.processesTranslation[processIndex]['steps'],

//   substeps: (state) => (processIndex, stepIndex) =>
//     state.processes[processIndex]['steps'][stepIndex]['substeps'],

//   substepsTranslation: (state) => (processIndex, stepIndex) =>
//     state.processesTranslation[processIndex]['steps'][stepIndex]['substeps'],

//   stepsCount: (state) => (processIndex) =>
//     state.processes[processIndex]?.steps.length,

//   substepsCount: (state) => (processIndex, stepIndex) =>
//     state.processes[processIndex]?.steps[stepIndex]?.substeps.length,

//   currentEditMode: (state) => state.currentEditMode,
//   editModes: (state) => state.editModes,
//   prevStepsJson: (state) => state.prevStepsJson,
//   updatedJsonPayload: (state) => state.updatedJsonPayload,

//   updatedJson: (state) => {
//     const temp = {};
//     state.processes.forEach((process) => {
//       process.steps.forEach((step) => {
//         const { name: stepName, substeps } = step;
//         temp[stepName] = {
//           [stepName]: {
//             typeP: 4,
//             step: stepName,
//             labelText: stepName,
//             secondMax: 0,
//             isBeingLabled: false,
//           },
//         };

//         substeps.forEach((substep) => {
//           temp[stepName][substep] = {
//             typeP: 3,
//             step: stepName,
//             labelText: substep,
//           };
//         });
//       });
//     });
//     return temp;
//   },
// };

// const actions = {
//   resetState({commit}){
//     commit(types.SET_PROCESS, []);
//     commit(types.SET_NEW_TASK, '');
//     commit(types.SET_UPDATED_JSON_PAYLOAD, []);
//   },
//   setNewTask({ commit }, payload) {
//     commit(types.SET_NEW_TASK, payload);
//   },

//   setTaskUpdateConfirmModal({ commit }, payload) {
//     commit(types.SET_TASK_UPDATE_CONFIRM_MODAL, payload);
//   },

//   setProcesses({ commit }, payload) {
//     commit(types.SET_PROCESS, payload);
//   },

//   setProcessesTranslation({ commit }, payload) {
//     commit(types.SET_PROCESS_TRANSLATION, payload);
//   },

//   addProcess({ commit, state }) {
//     const processes = [...state.processes];
//     processes.push({
//       name: '',
//       steps: [],
//     });
//     commit(types.SET_PROCESS, processes);
//   },

//   updateProcess({ commit, state }, payload) {
//     const { processIndex, process } = payload;
//     const processes = [...state.processes];
//     processes[processIndex]['name'] = process;
//     commit(types.SET_PROCESS, processes);
//   },

//   updateProcessTranslation({ commit, state }, payload) {
//     const { processIndex, process } = payload;
//     const processes = [...state.processesTranslation];
//     processes[processIndex]['name'] = process;
//     commit(types.SET_PROCESS_TRANSLATION, processes);
//   },

//   removeProcess({ commit, state }, payload) {
//     const { processIndex } = payload;
//     const processes = state.processes.filter(
//       (_, index) => index !== processIndex
//     );

//     commit(types.SET_PROCESS, processes);
//   },

//   addStep({ commit, state }, payload) {
//     const { processIndex } = payload;
//     const processes = [...state.processes];
//     const processSteps = processes[processIndex]['steps'];
//     if (!processSteps.at(-1)?.name && !!processSteps.length) {
//       toast.info('Please finish the last step first!', {
//         timeout: 2000,
//       });
//       return;
//     }
//     processes[processIndex]['steps'].push({
//       name: '',
//       substeps: [],
//     });

//     commit(types.SET_PROCESS, processes);
//   },

//   updateStep({ commit, state }, payload) {
//     const { step, stepIndex, processIndex } = payload;
//     const processes = [...state.processes];
//     const processSteps = [...processes[processIndex]['steps']];
//     processSteps[stepIndex]['name'] = step;
//     processes[processIndex]['steps'] = processSteps;
//     commit(types.SET_PROCESS, processes);
//   },

//   updateStepTranslation({ commit, state }, payload) {
//     const { step, stepIndex, processIndex } = payload;
//     const processes = [...state.processesTranslation];
//     const processSteps = [...processes[processIndex]['steps']];
//     processSteps[stepIndex]['name'] = step;
//     processes[processIndex]['steps'] = processSteps;
//     commit(types.SET_PROCESS_TRANSLATION, processes);
//   },

//   removeStep({ commit, state }, payload) {
//     const { stepIndex, processIndex } = payload;
//     const processes = [...state.processes];
//     const steps = processes[processIndex]['steps'].filter(
//       (_, index) => index !== stepIndex
//     );
//     processes[processIndex]['steps'] = steps;
//     commit(types.SET_PROCESS, processes);
//   },

//   moveStep({ commit, state }, payload) {
//     const { processIndex, stepIndex, direction } = payload;
//     const stepsLastIndex = state.processes[processIndex]['steps'].length - 1;
//     if (
//       (stepIndex === 0 && direction === 'up') ||
//       (stepIndex === stepsLastIndex && direction === 'down')
//     )
//       return;

//     const destinationIndex = getDestinationIndex(stepIndex, direction);
//     const processes = [...state.processes];
//     const steps = [...processes[processIndex]['steps']];
//     const temp = steps[stepIndex];
//     steps[stepIndex] = steps[destinationIndex];
//     steps[destinationIndex] = temp;
//     processes[processIndex]['steps'] = steps;
//     commit(types.SET_PROCESS, processes);
//   },

//   addSubstep({ commit, state }, payload) {
//     const { stepIndex, processIndex } = payload;
//     const processes = [...state.processes];
//     const substeps = processes[processIndex]['steps'][stepIndex]['substeps'];
//     if (!substeps.at(-1) && !!substeps.length) {
//       toast.info('Please finish the last sub-step first!', {
//         timeout: 2000,
//       });
//       return;
//     }
//     processes[processIndex]['steps'][stepIndex]['substeps'].push('');
//     commit(types.SET_PROCESS, processes);
//   },

//   updateSubstep({ commit, state }, payload) {
//     const { substep, substepIndex, stepIndex, processIndex } = payload;
//     const processes = [...state.processes];
//     processes[processIndex]['steps'][stepIndex]['substeps'][
//       substepIndex
//     ] = substep;
//     commit(types.SET_PROCESS, processes);
//   },

//   updateSubstepTranslation({ commit, state }, payload) {
//     const { substep, substepIndex, stepIndex, processIndex } = payload;
//     const processes = [...state.processesTranslation];
//     processes[processIndex]['steps'][stepIndex]['substeps'][
//       substepIndex
//     ] = substep;
//     commit(types.SET_PROCESS_TRANSLATION, processes);
//   },

//   removeSubstep({ commit, state }, payload) {
//     const { substepIndex, stepIndex, processIndex } = payload;
//     const processes = [...state.processes];
//     processes[processIndex]['steps'][stepIndex]['substeps'] = processes[
//       processIndex
//     ]['steps'][stepIndex]['substeps'].filter(
//       (_, index) => index !== substepIndex
//     );
//     commit(types.SET_PROCESS, processes);
//   },

//   moveSubstep({ commit, state }, payload) {
//     const { processIndex, stepIndex, substepIndex, direction } = payload;
//     const substepsLastIndex =
//       state.processes[processIndex]['steps'][stepIndex]['substeps'].length - 1;
//     if (
//       (substepIndex === 0 && direction === 'up') ||
//       (substepIndex === substepsLastIndex && direction === 'down')
//     )
//       return;

//     const destinationIndex = getDestinationIndex(substepIndex, direction);
//     const processes = [...state.processes];
//     const substeps = [
//       ...processes[processIndex]['steps'][stepIndex]['substeps'],
//     ];

//     const temp = substeps[substepIndex];
//     substeps[substepIndex] = substeps[destinationIndex];
//     substeps[destinationIndex] = temp;
//     processes[processIndex]['steps'][stepIndex]['substeps'] = substeps;
//     commit(types.SET_PROCESS, processes);
//   },

//   resetProcessState({ commit }) {
//     commit(types.SET_PROCESS, []);
//   },

//   setPrevStepsJson({ commit }, payload) {
//     commit(types.SET_PREV_STEPS_JSON, payload);
//   },

//   setCurrentEditMode({ commit }, payload) {
//     commit(types.SET_CURRENT_EDIT_MODE, payload);
//   },

//   setUpdatedJsonPayload({ commit }, payload) {
//     commit(types.SET_UPDATED_JSON_PAYLOAD, payload);
//   },

//   async upsertTask({ commit, state, getters, dispatch }) {
//     const { taskEdit, updatedJsonPayload } = state;
//     if (validateForSpecialCharacters(state)) return;
//     if (!taskEdit && (await checkIfTaskExist(state))) return;
//     if (checkIfEmpty(state)) return;
//     if (checkIfSubstepsExists(state)) return;
//     if (isDuplicateStep(state)) return;
//     if (isDuplicateSubstep(state)) return;
//     if (hasNoProcess(state)) return;
//     if (hasNoSteps(state)) return;
//     const taskString = getTaskString(state);

//     if (taskEdit) startTaskModification(commit, getters);
//     else dispatch('addTask');
//   },

//   async addTask({ state }) {
//     const { newTask, taskString } = state;
//     const payload = {
//       taskName: newTask,
//       task_detail: taskString,
//       stepsCount: getStepsCount(),
//       Organization: localStorage.getItem('organization'),
//     };
//     const [error, data] = await TaskService.addTask(payload);

//   },
// };

// const mutations = {
//   [types.SET_PROCESS](state, payload) {
//     state.processes = payload;
//   },
//   [types.SET_PROCESS_TRANSLATION](state, payload) {
//     state.processesTranslation = payload;
//   },
//   [types.SET_PREV_STEPS_JSON](state, payload) {
//     state.prevStepsJson = payload;
//   },
//   [types.SET_CURRENT_EDIT_MODE](state, payload) {
//     state.currentEditMode = payload;
//   },
//   [types.SET_NEW_TASK](state, payload) {
//     state.newTask = payload;
//   },
//   [types.SET_UPDATED_JSON_PAYLOAD](state, payload) {
//     state.updatedJsonPayload = payload;
//   },
//   [types.SET_TASK_UPDATE_CONFIRM_MODAL](state, payload) {
//     state.taskUpdateConfirmModal = payload;
//   },
// };

export default {
  state: {},
  getters: {},
  actions: {},
  mutations: {},
};
