export const msalConfig = {
  auth: {
    clientId: 'c5093c91-21ae-4374-9be1-d95791ea78eb',
    authority: 'https://login.microsoftonline.com/organizations',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const request = {
  scopes: ['openid', 'profile', 'User.Read', 'email'],
};
