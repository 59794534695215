export default {
  toggleSpinner(state, value) {
    state.spinner = value;
  },

  changeRoute(state, value) {
    state.currentRoute = value;
  },
  changeRouteConfig(state, object) {
    state.routeDetail = {
      ...object,
    };
  },

  setTaskId(state, value) {
    state.taskId = value;
  },

  updateSidebarState(state, value) {
    state.sidebar = value;
  },

  updateTheme(state, value) {
    state.theme = value;
  },

  setCurrentObject(state, obj) {
    state.currentObject = obj;
  },

  startTour(state, value) {
    state.isTourRunning = value;
  },

  stopTour(state, value) {
    state.isTourRunning = value;
  },

  resetState(state) {
    state.taskId = null;
  },

  setPrevMetaData(state, value) {
    state.prevMetaData = value;
  },

  setTotalFrames(state, value) {
    state.totalFrames = value;
  },
};
