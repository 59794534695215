export const eventTypes = {
  'Task Update': 'Task Update',
};

export const columns = [
  {
    title: 'Event',
    dataIndex: 'event_type',
    align: 'center',
  },
  {
    title: 'Recipients',
    dataIndex: 'recipients',
    align: 'center',
  },
  {
    title: 'Organization',
    dataIndex: 'Organization',
    align: 'center',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    align: 'center',
  },
];

export const eventTypeOptions = [
  { label: 'Task Update', value: 'Task Update' },
];
